import { InternalLink, Text, Title } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack } from "@mui/material";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { AgentStage } from "@src/appV2/Agents";
import {
  AttendanceScoreProfileResponse,
  LatenessConfigResponse,
} from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { defaultAttendanceScoreFeatureFlag } from "@src/appV2/AttendanceScore/constants";
import { useClipboardScoreRollout } from "@src/appV2/AttendanceScore/hooks/featureFlags";
import { getScoreColor, getScoreType } from "@src/appV2/AttendanceScore/utils";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { ATeamHcpStatus } from "@src/appV2/Rankings/types";
import { useGetATeamWorkplaces } from "@src/appV2/Rankings/useGetATeamWorkplaces";
import { useReliabilityRankingExperiment } from "@src/appV2/ReliabilityRank/useReliabilityRankExperiment";
import { type Shift } from "@src/lib/interface";
import { differenceInHours, isAfter, parseISO } from "date-fns";
import { ReactElement, useMemo } from "react";

import { MAX_ATTENDANCE_SCORE } from "../constants";
import { getPoints } from "../utils/attendanceScore";

interface ShiftAttendanceScoreAccordionProps {
  shift: Shift;
  attendanceScoreProfile: AttendanceScoreProfileResponse;
}

export const clockedInMessage = (pointsForWorking: number) =>
  `You're clocked in. You'll earn ${pointsForWorking} points for working the shift to completion.`;

const ClipboardScoreLink = () => (
  <InternalLink
    to={TabRouterPath.RATINGS_ATTENDANCE_SCORE}
    sx={{
      color: (theme) => theme.palette.text.secondary,
      textDecorationColor: (theme) => theme.palette.text.secondary,
    }}
  >
    Learn about Clipboard Score
  </InternalLink>
);

export const ongoingShiftMessageText =
  "Running late? Tap 'Late for Shift' below to update the facility. A No-Call-No-Show will result in an account Restriction. Facilities reserve the right to decline any late arrivals. ";

const MessageWithLink = ({
  children,
  isClipboardScoreEnabled,
}: {
  children: React.ReactNode;
  isClipboardScoreEnabled: boolean;
}) => (
  <Text>
    {children}
    {isClipboardScoreEnabled && (
      <>
        {" "}
        <ClipboardScoreLink />
      </>
    )}
  </Text>
);

export const ongoingShiftMessage = (isClipboardScoreEnabled: boolean) => (
  <Text variant="body2">
    {ongoingShiftMessageText}
    {isClipboardScoreEnabled && <ClipboardScoreLink />}
  </Text>
);

export const restrictedATeamWorkerMessage = `You're able to work this shift during your Restriction either because you have Priority Plus at this facility or you were invited. Arrive on time and avoid cancelling to stay in good standing with them.`;

export const restrictedNonATeamWorkerMessage = `You're able to work this shift during your Restriction either because you're a Favorite at this facility or you were invited. Arrive on time and avoid cancelling to stay in good standing with them.`;

export const urgentlyBookedShiftMessage = (onTimePoints: number) =>
  `Because this is a last minute shift, you won't lose points for timeliness. You can still earn ${onTimePoints} extra points by arriving on time.`;

export const possibleRestrictionMessage = `A score of zero or below will result in an account Restriction.`;

// This function converts the lateness config to text of the form:
// "You'll lose 25 points for being >10 minutes late, 30 points for being >30 minutes late,
// and 50 points for being >50 minutes late"
function getLatenessPointsMessage(latenessConfig?: LatenessConfigResponse) {
  if (!isDefined(latenessConfig) || latenessConfig.length === 0) {
    return "";
  }
  const latenessPointsMessages = latenessConfig.map(
    (config) =>
      `${Math.abs(config.points)} points for being >${
        config.minutesLateRange.startFrom
      } minutes late`
  );
  const combinedLatenessPointsMessage =
    latenessPointsMessages.length === 1
      ? latenessPointsMessages.at(0)
      : latenessPointsMessages.slice(0, -1).join(", ") + ", and " + latenessPointsMessages.at(-1);

  return `You'll lose ${combinedLatenessPointsMessage}.`;
}

function getDescription(
  shift: Shift,
  attendanceScoreProfile: AttendanceScoreProfileResponse,
  aTeamStatus: ATeamHcpStatus,
  disableSecondShiftBonus: boolean,
  isClipboardScoreEnabled = false,
  isReliabilityRankingEnabled = false
) {
  const isClockedIn = isDefined(shift.clockInOut?.start) && !isDefined(shift.clockInOut?.end);
  const workPoints = attendanceScoreProfile.policy?.workShift?.points ?? 0;
  const isSecondShiftInDouble = shift.isAfterAnotherShift || shift.isSecondShiftInDouble;

  const secondShiftPointsMessage = `Since this is the second shift in a double, you won't ${
    disableSecondShiftBonus && "earn or "
  }lose points for clocking in late.`;

  if (isClockedIn) {
    if (disableSecondShiftBonus && isSecondShiftInDouble) {
      return (
        <MessageWithLink isClipboardScoreEnabled={isClipboardScoreEnabled}>
          {secondShiftPointsMessage}
        </MessageWithLink>
      );
    }
    return (
      <MessageWithLink isClipboardScoreEnabled={isClipboardScoreEnabled}>
        {clockedInMessage(workPoints)}
      </MessageWithLink>
    );
  }

  const currentTime = new Date();
  const isShiftOngoing = isDefined(shift.start) && isAfter(currentTime, new Date(shift.start));
  if (isShiftOngoing) {
    if (disableSecondShiftBonus && isSecondShiftInDouble) {
      return (
        <MessageWithLink isClipboardScoreEnabled={isClipboardScoreEnabled}>
          {secondShiftPointsMessage}
        </MessageWithLink>
      );
    }
    return ongoingShiftMessage(isClipboardScoreEnabled);
  }

  const isRestricted = attendanceScoreProfile?.accountStatus.status === AgentStage.RESTRICTED;
  if (isRestricted) {
    return (
      <MessageWithLink isClipboardScoreEnabled={isClipboardScoreEnabled}>
        {aTeamStatus === ATeamHcpStatus.A_TEAM
          ? restrictedATeamWorkerMessage
          : restrictedNonATeamWorkerMessage}
      </MessageWithLink>
    );
  }

  const points = getPoints(attendanceScoreProfile, shift.start!);
  const isMaxScore = (attendanceScoreProfile?.score ?? 0) >= MAX_ATTENDANCE_SCORE;
  const showPossibleRestrictionMessage =
    attendanceScoreProfile.score -
      Math.max(points.cancelPointsNow, ...points.latenessPoints.map((config) => config.points)) <=
    0;

  let pointsMessage: string;
  if (shift.urgentlyBooked) {
    pointsMessage = urgentlyBookedShiftMessage(points.onTimePoints);
  } else if (isSecondShiftInDouble) {
    pointsMessage = secondShiftPointsMessage;
  } else {
    pointsMessage = `Earn ${
      points.onTimePoints
    } extra points by arriving on time! ${getLatenessPointsMessage(
      attendanceScoreProfile.policy?.latenessConfig
    )}`;
  }

  return (
    <Stack spacing={2}>
      <Text>
        {isMaxScore && !isClipboardScoreEnabled && !isReliabilityRankingEnabled
          ? `You have the maximum score. You'll `
          : `You'll earn ${points.workPoints} points for working the shift, and `}
        lose {points.cancelPointsNow} points if you cancel right now.
      </Text>
      <Text>{pointsMessage}</Text>
      {showPossibleRestrictionMessage && !shift.urgentlyBooked && !isSecondShiftInDouble && (
        <Text>{possibleRestrictionMessage}</Text>
      )}
      {isClipboardScoreEnabled && (
        <Text>
          <ClipboardScoreLink />
        </Text>
      )}
    </Stack>
  );
}

export function ShiftAttendanceScoreAccordion({
  shift,
  attendanceScoreProfile,
}: ShiftAttendanceScoreAccordionProps): ReactElement {
  const { aTeamStatus } = useGetATeamWorkplaces();
  const { disableSecondShiftBonus } = useCbhFlag(CbhFeatureFlag.ATTENDANCE_SCORE_INFO, {
    defaultValue: defaultAttendanceScoreFeatureFlag,
  });
  const {
    score = 0,
    clipboardScore = 0,
    accountStatus,
    reliabilityScore,
    reliabilityScoreRank,
  } = attendanceScoreProfile;

  const reliabilityRankingExperiment = useReliabilityRankingExperiment({
    reliabilityScore,
    reliabilityScoreRank,
  });
  const isClipboardScoreEnabled = useClipboardScoreRollout();

  const profileScore = useMemo(() => {
    if (reliabilityRankingExperiment.enabled) {
      return reliabilityRankingExperiment.data.reliabilityScore;
    }

    if (isClipboardScoreEnabled) {
      return clipboardScore;
    }

    return score;
  }, [
    reliabilityRankingExperiment.enabled,
    reliabilityRankingExperiment.data?.reliabilityScore,
    isClipboardScoreEnabled,
    score,
    clipboardScore,
  ]);

  const scoreType = getScoreType({
    isReliabilityRankingExperimentEnabled: reliabilityRankingExperiment.enabled,
    isClipboardScoreEnabled,
  });

  const isRestricted = accountStatus.status === AgentStage.RESTRICTED;
  const hoursBeforeShift = isDefined(shift.start)
    ? differenceInHours(parseISO(shift.start), new Date(), { roundingMethod: "ceil" })
    : 0;

  return (
    <>
      <Accordion defaultExpanded={hoursBeforeShift > 24}>
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ mx: 1 }} />}>
          <Title bold component="h4" data-testid="attendance-score-title">
            {scoreType}
            {!reliabilityRankingExperiment.enabled && (
              <>
                :{" "}
                <Box
                  component="span"
                  sx={{
                    color: isRestricted ? "red" : getScoreColor(profileScore),
                  }}
                >
                  {isRestricted ? "Restricted" : profileScore}
                </Box>
              </>
            )}
          </Title>
        </AccordionSummary>
        <AccordionDetails data-testid="attendance-score-description">
          {getDescription(
            shift,
            attendanceScoreProfile,
            aTeamStatus,
            disableSecondShiftBonus,
            isClipboardScoreEnabled,
            reliabilityRankingExperiment.enabled
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
